import { TakeoverAd } from 'js/components/specific/top/TakeoverAd/type';
import { EcnFrames } from 'js/const/ecnFrames';

export const TAKEOVER_AD_MAP: Record<string, TakeoverAd> = {
  // ループで処理できないため、1~4件目のみ有効とする
  temu_202410: {
    linkUrl: `/ad/10709703/show/?ecnframe=${EcnFrames.TAKEOVER_AD}`,
    isTargetBlank: false,
    description: 'Temu',
    headerBannerUrl: '/top/img/takeover/temu_202406_header.png',
    firstSideBannerUrl: '/top/img/takeover/temu_202406_side1.png',
    secondSideBannerUrl: '/top/img/takeover/temu_202406_side2.png',
    background: 'linear-gradient(rgb(255 140 41), rgb(255 201 0) 3000px)',
  },
  qoo10_second_202410: {
    linkUrl: `/campaign/qoo10202410/?ecnframe=${EcnFrames.TAKEOVER_AD}`,
    isTargetBlank: false,
    description: 'Qoo10 メガポ',
    headerBannerUrl: '/top/img/takeover/qoo10_202410_header.jpg',
    firstSideBannerUrl: '/top/img/takeover/qoo10_202410_side1.jpg',
    secondSideBannerUrl: '/top/img/takeover/qoo10_202410_side2.jpg',
    background: '#1f57fa',
  },
  qoo10_first_202410: {
    linkUrl: `/campaign/qoo10202410/?ecnframe=${EcnFrames.TAKEOVER_AD}`,
    isTargetBlank: false,
    description: 'Qoo10 メガポ',
    headerBannerUrl: '/top/img/takeover/qoo10_202410_header.jpg',
    firstSideBannerUrl: '/top/img/takeover/qoo10_202410_side1.jpg',
    secondSideBannerUrl: '/top/img/takeover/qoo10_202410_side2.jpg',
    background: '#1f57fa',
  },
  olieve_202409: {
    linkUrl: `/ad/10707549/show/?ecnframe=${EcnFrames.TAKEOVER_AD}`,
    isTargetBlank: false,
    description: 'Olive口座開設',
    headerBannerUrl: '/top/img/takeover/olieve_202409_header.png',
    firstSideBannerUrl: '/top/img/takeover/olieve_202409_side1.png',
    secondSideBannerUrl: '/top/img/takeover/olieve_202409_side2.png',
    background: '#004831',
  },
};
