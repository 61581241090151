import React, { Dispatch, SetStateAction } from 'react';
import {
  IdentificationNotice,
  Props as IdentificationNoticeProps,
} from 'js/components/specific/top/IdentificationNotice';
import { SignUpAppeal } from 'js/components/common/SignUpAppeal';
import {
  TakeoverAd,
  Props as TakeoverAdProps,
} from 'js/components/specific/top/TakeoverAd';

import { SlideBanner } from 'js/components/specific/top/SlideBanner/SlideBanner';
import {
  FirstEnquete,
  Props as FirstEnqueteProps,
} from 'js/components/specific/top/FirstEnquete';
import { Props as PointUpChallengeItemProps } from 'js/components/common/Affiliate/PointUpChallengeItem';
import { PointUpChallengeList } from 'js/components/common/Affiliate/PointUpChallengeList';
import {
  LimitedHighBonus,
  Props as LimitedHighBonusProps,
} from 'js/components/specific/top/LimitedHighBonus';
import { ResearchBannerList } from 'js/components/specific/top/Research/ResearchBannerList';
import { AppealFrame } from 'js/components/ui/AppealFrame';
import { Props as ResearchBannerItemProps } from 'js/components/specific/top/Research/ResearchBannerItem';
import { AffiliateList } from 'js/components/common/Affiliate/AffiliateList';
import { AffiliateItemType } from 'js/types/affiliate';
import { AffiliateSimpleList } from 'js/components/common/Affiliate/AffiliateSimpleList';
import { Props as CategoryItemProps } from 'js/components/common/Category/CategoryItem';
import { CategoryList } from 'js/components/common/Category/CategoryList';
import { InFrameBannerList } from 'js/components/specific/top/InFrameBanner/InFrameBannerList';
import { Props as BannerItemProps } from 'js/components/common/Banner/BannerItem';
import { DisasterSupport } from 'js/components/specific/top/DisasterSupport';
import { PrBanner } from 'js/components/ui/PrBanner';
import sidebar1st from '!!raw-loader!js/tag/advertisement/pc/top/sidebar1st.php';
import sidebar2nd from '!!raw-loader!js/tag/advertisement/pc/top/sidebar2nd.php';
import { RpRetargetingTag } from 'js/components/common/layout/Tag/RpRetargetingTag';
import { ZucksRetargetingTag } from 'js/components/common/layout/Tag/ZucksRetargetingTag';
import { Notification } from 'js/components/specific/top/Notification';
import { BokinCampaign } from 'js/components/specific/top/DisasterSupport/type';
import { AffiliateSkeletonList } from 'js/components/common/Affiliate/AffiliateSkeletonList';
import { InFrameBannerSkeletonList } from 'js/components/specific/top/InFrameBanner/InFrameBannerSkeletonList';
import { Chomedama } from 'js/components/specific/top/Chomedama/Chomedama';
import { ChomedamaItem } from 'js/components/specific/top/Chomedama/Chomedama/type';
import { TeibanService } from 'js/components/specific/top/TeibanService';
import { TeibanServiceItem } from 'js/components/specific/top/TeibanService/type';
import { SignUpCampaignType } from 'js/components/specific/top/SignUpCampaign/type';
import { SignUpCampaign } from 'js/components/specific/top/SignUpCampaign';
import { Ranking } from 'js/components/specific/top/Ranking';
import { CategoryBasedRanking } from 'js/types/ranking';

export type Props = {
  isLogin?: boolean;
  isLoading: boolean;
  ecnaviCampaigns?: BannerItemProps[];
  identificationNotice?: IdentificationNoticeProps;
  topSlideBanner: AffiliateItemType[];
  firstenq?: FirstEnqueteProps;
  signUpCampaign: SignUpCampaignType;
  pickup: AffiliateItemType[];
  pointUpChallenge: PointUpChallengeItemProps[];
  limitedHighBonus: LimitedHighBonusProps[];
  research?: ResearchBannerItemProps[];
  timesale: AffiliateItemType[];
  ranking?: CategoryBasedRanking[];
  medamaShop: AffiliateItemType[];
  navicInfo: AffiliateItemType[];
  ichioshi: AffiliateItemType[];
  teibanService: TeibanServiceItem[];
  categories: CategoryItemProps[];
  bokinCampaign: BokinCampaign[];
  newArrivalServiceItems?: AffiliateItemType[];
  campaigns?: BannerItemProps[];
  featuredShops: AffiliateItemType[];
  chomedama?: ChomedamaItem;
  staffChoice: AffiliateItemType[];
  ck?: string;
  nickname?: string;
  notificationProcessing: boolean;
  setNotificationProcessing: Dispatch<SetStateAction<boolean>>;
  takeoverAd?: TakeoverAdProps['takeoverAd'];
};

export const TopPresenter: React.FC<Props> = ({
  isLogin,
  isLoading,
  identificationNotice,
  topSlideBanner,
  firstenq,
  signUpCampaign,
  pointUpChallenge,
  limitedHighBonus,
  research,
  timesale,
  medamaShop,
  navicInfo,
  ichioshi,
  teibanService,
  pickup,
  categories,
  ecnaviCampaigns,
  bokinCampaign,
  newArrivalServiceItems,
  campaigns,
  featuredShops,
  chomedama,
  ranking,
  staffChoice,
  ck,
  nickname,
  notificationProcessing,
  setNotificationProcessing,
  takeoverAd,
}) => (
  <>
    {/* ▼ 幅広カラム */}
    <div className="c_main-contents__column width-wide">
      {identificationNotice && (
        <IdentificationNotice {...identificationNotice} />
      )}
      {typeof isLogin !== 'undefined' && !isLogin && (
        <SignUpAppeal needSignupCID={false} />
      )}
    </div>
    {/* △ 幅広カラム */}

    <TakeoverAd takeoverAd={takeoverAd}>
      {/* ▼ メインコンテンツ ラッパー */}
      <div className="c_main-contents__wrapper has-multiple-columns">
        {/* ▼ 左カラム */}
        <div className="c_main-contents__column position-left">
          {!!topSlideBanner?.length && <SlideBanner list={topSlideBanner} />}
          {firstenq && <FirstEnquete {...firstenq} />}
          {!!pointUpChallenge?.length && (
            <AppealFrame
              heading="ポイントアップチャレンジ"
              contentsLink="/contents/point_up_challenge/"
            >
              <div className="point-up-challenge__wrapper view-top">
                <PointUpChallengeList
                  list={pointUpChallenge.slice(0, 4)}
                  viewType="top"
                />
              </div>
            </AppealFrame>
          )}
          {/* 1件しかないので1件目を表示する */}
          {!!limitedHighBonus.length && (
            <LimitedHighBonus
              affiliateItem={limitedHighBonus[0].affiliateItem}
              endAt={limitedHighBonus[0].endAt}
              totalCount={limitedHighBonus[0].totalCount}
              remainingCount={limitedHighBonus[0].remainingCount}
            />
          )}
          {isLogin ? (
            <AppealFrame
              heading="おすすめアンケート"
              contentsLink="/research/#product-enquete"
            >
              {research ? (
                <ResearchBannerList list={research} column={4} />
              ) : (
                <AffiliateSkeletonList count={4} column={4} />
              )}
            </AppealFrame>
          ) : null}
          {!!timesale?.length && (
            <AppealFrame heading="タイムセール">
              <AffiliateList list={timesale} column={4} />
            </AppealFrame>
          )}
          {!!ranking?.length && (
            <div id="ranking">
              <AppealFrame heading="カテゴリ別ランキング">
                <Ranking list={ranking} column={5} />
              </AppealFrame>
            </div>
          )}
          {!!medamaShop?.length && (
            <AppealFrame
              heading="目玉ショップ"
              contentsLink="/search/?sort=Point&pointType=2"
            >
              <AffiliateList list={medamaShop} column={4} />
            </AppealFrame>
          )}
          {!!navicInfo?.length && (
            <AppealFrame heading="ナビック新着情報">
              <AffiliateList list={navicInfo} column={4} />
            </AppealFrame>
          )}
          {!!ichioshi?.length && (
            <AppealFrame heading="イチオシ">
              <AffiliateList list={ichioshi} column={4} />
            </AppealFrame>
          )}
        </div>
        {/* △ 左カラム */}

        {/* ▼ 右カラム */}
        <div className="c_main-contents__column position-right">
          <PrBanner size="300x250" adInnerHtml={sidebar1st} />
          {!!teibanService?.length && (
            <AppealFrame heading="定番サービス">
              <TeibanService teibanService={teibanService} column={3} />
            </AppealFrame>
          )}
          {signUpCampaign.linkUrl && signUpCampaign.bannerUrl && (
            <AppealFrame heading="入会キャンペーン">
              <SignUpCampaign signUpCampaign={signUpCampaign} />
            </AppealFrame>
          )}
          {!!pickup?.length && (
            <AppealFrame heading="ピックアップ">
              <AffiliateList list={pickup} column={1} />
            </AppealFrame>
          )}
          {!!categories?.length && (
            <AppealFrame heading="カテゴリで探す">
              <CategoryList {...{ categories }} />
            </AppealFrame>
          )}
          {
            <AppealFrame heading="ECナビキャンペーン">
              {!!newArrivalServiceItems?.length ? (
                <InFrameBannerList
                  list={ecnaviCampaigns}
                  column={1}
                  type="wide"
                />
              ) : (
                <InFrameBannerSkeletonList count={6} column={1} type="wide" />
              )}
            </AppealFrame>
          }
          {!!bokinCampaign?.length && (
            <DisasterSupport {...{ bokinCampaign }} />
          )}
          <PrBanner size="300x250" adInnerHtml={sidebar2nd} />
        </div>
        {/* △ 右カラム */}
      </div>
      {/* △ メインコンテンツ ラッパー */}

      {/* ▼ 幅広カラム */}
      <div className="c_main-contents__column width-wide">
        {
          <AppealFrame
            heading="新着サービス"
            contentsLink="/search/?sort=Newer"
          >
            {!!newArrivalServiceItems?.length ? (
              <AffiliateList list={newArrivalServiceItems} column={6} />
            ) : (
              <AffiliateSkeletonList count={6} column={6} />
            )}
          </AppealFrame>
        }
        {
          <AppealFrame heading="特集・キャンペーン" contentsLink="/campaign/">
            {!!campaigns?.length ? (
              <InFrameBannerList list={campaigns} column={6} />
            ) : (
              <InFrameBannerSkeletonList count={12} column={6} />
            )}
          </AppealFrame>
        }
        {!!staffChoice?.length && (
          <AppealFrame heading="スタッフチョイス">
            <AffiliateSimpleList list={staffChoice} column={9} />
          </AppealFrame>
        )}
        {/*
        TODO: 注目ショップで別途使用していた個別のコンポーネントは削除したので
              shop_point.jsを廃止する
        */}
        {!!featuredShops?.length && (
          <AppealFrame heading="注目ショップ">
            <AffiliateSimpleList list={featuredShops} column={9} />
          </AppealFrame>
        )}
      </div>
      {/* △ 幅広カラム */}
    </TakeoverAd>

    {!isLoading && isLogin && (
      <Notification
        ck={ck}
        nickname={nickname}
        setProcessing={setNotificationProcessing}
      />
    )}
    {isLogin && chomedama && !notificationProcessing && (
      <Chomedama item={chomedama} />
    )}
    <RpRetargetingTag />
    <ZucksRetargetingTag />
  </>
);
